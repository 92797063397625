import * as React from 'react';
import { VaIcon } from '@department-of-veterans-affairs/component-library/dist/react-bindings';
import classNames from 'classnames';
import './TestingNotice.scss';

export const TestingNotice = (): JSX.Element => (
  <div
    className={classNames(
      'va-api-testing-notice',
      'vads-u-color--white',
      'vads-u-font-weight--bold',
      'vads-u-font-size--sm',
      'vads-u-padding-y--0p5',
      'vads-u-padding-left--0p5',
    )}
  >
    <VaIcon icon="warning" /> This site is for testing. Please click{' '}
    <a className="vads-u-color--white" href="https://developer.va.gov">
      developer.va.gov
    </a>{' '}
    to visit VA API Platform.
  </div>
);
