import * as React from 'react';
import { SectionHeaderWrapper } from '../../sectionHeaderWrapper/SectionHeaderWrapper';
import { testUserGitHubUrl } from '../../../utils/testUsersHelper';
import { ApiRequiredProps } from '../../../containers/documentation/DocumentationRoot';

const TestUsers = (props: ApiRequiredProps): JSX.Element => {
  const { api } = props;

  return (
    <>
      <SectionHeaderWrapper heading="Test user ICNs" id="test-users" />
      <p>
        You can get test users&apos; Integrated Control Numbers (ICNs) on the{' '}
        <a href={testUserGitHubUrl(api.urlSlug)}>test users page</a>. Search by the values indicated
        in your API documentation.
      </p>
    </>
  );
};

TestUsers.propTypes = {};

export { TestUsers };
