import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { TextField } from '../../../../components';

interface OAuthCcgAppInfoProps {
  ccgPublicKeyUrl: string;
  multipleTypes: boolean;
  isRestrictedAccessApi: boolean;
}

const OAuthCcgAppInfo: React.FC<OAuthCcgAppInfoProps> = ({
  ccgPublicKeyUrl,
  multipleTypes,
  isRestrictedAccessApi,
}): JSX.Element => (
  <div
    className={classNames('sandbox-access-form-oauth-details', {
      'multiple-types': multipleTypes,
    })}
  >
    <div>
      <p className="vads-u-margin-top--0 vads-u-font-weight--bold">Client Credentials Grant</p>
      <p className="vads-u-margin-bottom--0">
        To access an API that uses OAuth 2.0 Client Credentials Grant, you must provide an RSA
        public key in JSON Web Key (JWK) format.
      </p>
      <p className="vads-u-margin-y--0">
        <Link to={ccgPublicKeyUrl} target="_blank">
          Learn how to generate and format a public key
        </Link>
        .
      </p>
    </div>
    <TextField
      as="textarea"
      placeholder='{
  "kty": "RSA",
  "n": "mYi1wUpwkJ1QB8..."
  ...
}'
      label="OAuth public key"
      name="oAuthPublicKey"
      required
      className="vads-u-margin-top--2"
      otherError="Enter a valid RSA public key in JSON Web Key (JWK) format."
    />
    {isRestrictedAccessApi && (
      <p>
        <strong>Important:</strong> To get production access using client credentials grant, you
        must either work for the VA or have specific VA agreements in place. If you have questions,{' '}
        <a href="https://developer.va.gov/support/contact-us">contact us</a>.
      </p>
    )}
  </div>
);

export { OAuthCcgAppInfo };
