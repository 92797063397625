import React from 'react';
import './Pill.scss';
import { VaIcon } from '@department-of-veterans-affairs/component-library/dist/react-bindings';

type PillIconType = 'auth' | 'topic' | 'search';

interface PillIconProps {
  type: PillIconType | undefined;
}

const PillIcon = ({ type }: PillIconProps): JSX.Element | null => {
  switch (type) {
    case 'auth':
      return <VaIcon icon="key" size={2} />;
    case 'topic':
      return <VaIcon icon="sell" size={2} />;
    case 'search':
      return <VaIcon icon="search" size={2} />;
    default:
      return null;
  }
};

interface PillProps {
  name: string;
  onClick: () => void;
  type?: PillIconType | undefined;
}
export const Pill = ({ name, onClick, type }: PillProps): JSX.Element => (
  <button
    aria-label={`${name} filter - Remove`}
    className={`va-api-filter-pill ${type as string}-filter-pill`}
    title={`${name} filter - Remove`}
    type="button"
    onClick={onClick}
  >
    <PillIcon type={type} />
    <span>{name}</span>
    <VaIcon icon="cancel" size={2} />
  </button>
);
