import React from 'react';
import { createDeepLinkPath } from './utils';

// https://github.com/swagger-api/swagger-ui/blob/v4.19.1/src/core/components/operation-summary-path.jsx
const OperationSummaryPath = props => {
  const { getComponent, operationProps } = props;
  const { deprecated, isShown, path, tag, operationId, isDeepLinkingEnabled } =
    operationProps.toJS();

  /**
   * Add <wbr> word-break elements between each segment, before the slash
   * to allow browsers an opportunity to break long paths into sensible segments.
   */
  const pathParts = path.split(/(?=\/)/g);
  for (let i = 1; i < pathParts.length; i += 2) {
    pathParts.splice(i, 0, <wbr key={i} />);
  }

  const DeepLink = getComponent('DeepLink');

  return (
    <span
      className={deprecated ? 'opblock-summary-path__deprecated' : 'opblock-summary-path'}
      data-path={path}
    >
      {isDeepLinkingEnabled ? (
        <DeepLink
          enabled={isDeepLinkingEnabled}
          isShown={isShown}
          path={createDeepLinkPath(`${tag}/${operationId}`)}
          text={pathParts}
        />
      ) : (
        <span>{pathParts}</span>
      )}
    </span>
  );
};

export default OperationSummaryPath;
