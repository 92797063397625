import * as React from 'react';
import { CodeBlock } from '../../../components';

interface ExpectedSyntaxHighlighterProps extends React.PropsWithChildren {
  language: string;
}

export const WrapSyntaxHighlighter = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SyntaxHighlighter: (_Original: React.ComponentType): React.ComponentType => {
    const CustomSyntaxHighlighter: React.FC<ExpectedSyntaxHighlighterProps> = ({ children, language }) => (
      <CodeBlock
        code={children as string}
        language={language}
        withCopyButton
      />
    );

    return CustomSyntaxHighlighter;
  },
};
