import * as React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { setRequestedApiVersion } from '../../features/apis/apiVersioningSlice';
import { APIDescription } from '../../apiDefs/schema';
import { useAppDispatch } from '../../hooks';
import { SwaggerDocs } from './SwaggerDocs';

interface ApiDocumentationProps {
  apiDefinition: APIDescription;
}

const ApiDocumentation = (props: ApiDocumentationProps): React.ReactElement => {
  const { apiDefinition } = props;
  const { docSources, urlSlug } = apiDefinition;
  const location = useLocation();

  /*
   * API Version
   */
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const apiVersion = searchParams.get('version');

  React.useEffect((): void => {
    dispatch(setRequestedApiVersion(apiVersion));
  }, [dispatch, apiVersion, location.pathname]);

  /*
   * RENDER
   */
  return <SwaggerDocs docSource={docSources[0]} apiName={urlSlug} />;
};

export default ApiDocumentation;
