import * as React from 'react';
import { ApiTag, CheckboxRadioField } from '../index';
import { APIDescription } from '../../apiDefs/schema';
import { Flag } from '../../FlagsProvider';
import { FLAG_HOSTED_APIS } from '../../types/constants';
import './APICheckboxList.scss';
import {
  isApiAuthTypeProdBlocked,
  isApiAuthTypeRestricted,
} from '../../utils/restrictedAccessHelper';

interface APICheckboxListProps {
  apis: APIDescription[];
  authType: 'acg' | 'apikey' | 'ccg';
}

const ApiCheckboxList = ({ apis, authType }: APICheckboxListProps): React.ReactElement => (
  <div className="va-api-api-checkbox-list">
    {apis
      .filter(api => !isApiAuthTypeProdBlocked(api, authType))
      .map(api => (
        <Flag
          defaultValue={false}
          key={api.urlFragment}
          keyPath={[FLAG_HOSTED_APIS, api.urlFragment]}
          render={(): React.ReactElement => (
            <CheckboxRadioField
              className="va-api-checkbox-container"
              type="checkbox"
              name="apis"
              label={
                <>
                  <span className="vads-u-margin-right--1">{api.name}</span>
                  <span className="vads-u-display--inline-block">
                    {isApiAuthTypeRestricted(api, authType) && (
                    <ApiTag showLock tagName="Restricted Access" />
                  )}
                    {api.openData && <ApiTag tagName="Open Data" />}
                  </span>
                </>
              }
              value={`${authType}/${api.altID ?? api.urlSlug}`}
            />
          )}
        />
      ))}
  </div>
);

ApiCheckboxList.propTypes = {};

export { ApiCheckboxList };
