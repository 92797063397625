import React from 'react';
import { Field, Form, Formik } from 'formik';
import { VaIcon } from '@department-of-veterans-affairs/component-library/dist/react-bindings';

interface SearchFilterValues {
  search: string;
}

interface SearchFiltersProps {
  handleSearchSubmit: (values: SearchFilterValues) => void;
  search: string;
}
export const SearchFilters = ({ handleSearchSubmit, search }: SearchFiltersProps): JSX.Element => {
  const initialSearch: SearchFilterValues = { search };

  return (
    <Formik
      initialValues={initialSearch}
      onSubmit={handleSearchSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form noValidate id="explore-page-fuzzy-search" name="explore-fuzzy-search">
        <Field
          id="fuzzy-search"
          className="va-api-text-field"
          name="search"
          required={false}
          aria-invalid={false}
          type="text"
          placeholder="Search APIs by keyword"
          aria-label="Search APIs by keyword"
        />
        <button type="submit" className="display-inline" aria-label="Submit Search">
          <VaIcon icon="search" size={3} />
        </button>
      </Form>
    </Formik>
  );
};
