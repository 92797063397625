import * as React from 'react';

import { PAGE_HEADER_AND_HALO_ID, PAGE_HEADER_ID } from '../../types/constants';
import './PageHeader.scss';

interface PageHeaderProps {
  className?: string;
  halo?: string;
  header: string;
  subText?: string;
}

const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const { className, halo, header, subText } = props;

  return (
    <div id={PAGE_HEADER_AND_HALO_ID} className={className}>
      {halo && <div className="header-halo vads-u-color--gray">{halo}</div>}
      <h1
        data-cy="page-header"
        id={PAGE_HEADER_ID}
        className="page-header vads-u-margin-top--0 vads-u-margin-bottom--0"
        tabIndex={-1}
      >
        {header}
      </h1>
      {subText && (
        <h2 className="vads-u-font-size--h3 vads-u-color--gray-medium vads-u-margin-top--0">
          {subText}
        </h2>
      )}
    </div>
  );
};

export { PageHeader };
