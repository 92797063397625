import {
  VaProcessList,
  VaProcessListItem,
} from '@department-of-veterans-affairs/component-library/dist/react-bindings';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { PageHeader } from '../../components';
import {
  CONSUMER_APIS_PATH,
  CONSUMER_DEMO_PATH,
  CONSUMER_PROD_PATH,
} from '../../types/constants/paths';
import './ProductionAccessOverview.scss';

const ProductionAccessOverview = (): JSX.Element => (
  <>
    <Helmet>
      <title>Production access process</title>
    </Helmet>
    <PageHeader header="Production access process" />
    <p>
      VA&apos;s process of granting production access ensures the quality and security of
      applications integrating with our APIs and data. It may seem complex, but don&apos;t
      worry—we&apos;re in this together and are here to answer your questions each step of the way.
    </p>
    <h2 id="production-access-steps">Production access steps</h2>
    <VaProcessList uswds>
      <VaProcessListItem header="Start developing" id="start-developing">
        <p>
          Access to our sandbox environment is automatic when you request sandbox access{' '}
          <Link to="/explore">from an API&apos;s overview page</Link>.
        </p>
      </VaProcessListItem>
      <VaProcessListItem header="Request production access" id="request-prod-access">
        <p>Timeline for getting production access varies.</p>
        <p>
          <Link to={CONSUMER_PROD_PATH}>
            Learn what&apos;s needed on the production access form.
          </Link>
        </p>
      </VaProcessListItem>
      <VaProcessListItem header="Prepare for and complete a demo" id="demo">
        <p>
          We&apos;ll review your production access request. Any changes we require must be made
          before&nbsp;
          <Link to={CONSUMER_DEMO_PATH}>your demo</Link>. Open data APIs don&apos;t require a demo.
        </p>
      </VaProcessListItem>
      <VaProcessListItem header="Receive production access" id="receive-prod-access">
        <p>
          After a successful demo, you&apos;ll get production access. Learn more about&nbsp;
          <Link to={CONSUMER_APIS_PATH}>working with our APIs</Link>.
        </p>
      </VaProcessListItem>
    </VaProcessList>
    <h2>Production access timeline</h2>
    <p>
      The timeline for getting production access can vary based on the API. For open data APIs, it
      usually takes 1 to 3 months. For all other APIs, it can take 1 to 6 months.
    </p>
    <p>
      The speed of getting production access depends on many factors, including how fast we receive
      information from you, scheduling availability for demos (if needed), and how quickly required
      changes are made.
    </p>
    <p>
      Review what you need to send us before requesting production access. If we require changes,
      completing them quickly will help you get production access sooner.
    </p>
    <div className="consumer-production-access-overview">
      <h3>Requesting access to the production environment</h3>
      <p>
        Once you submit the production access form, we&apos;ll review your information and notify you
        within about 1 to 2 weeks if there are any changes we need.
      </p>

      <h3>Making any needed changes</h3>
      <p>
        The timeline for this step depends on how many changes are needed and the speed at which
        your team can complete them and notify us.
      </p>
      <h3>Scheduling and completing a demo</h3>
      <p>
        Once the changes are made, we aim to schedule the demo within a week. Demos generally last
        from 30 to 60 minutes. No demo is needed for open data APIs.
      </p>
      <h3>Getting production access</h3>
      <p>
        After the demo is complete and any final items are wrapped up, production access is
        generally granted in 1 to 4 months.
      </p>
    </div>
    <h2>About us</h2>
    <p>
      The VA Lighthouse APIs developer portal is your go-to source for VA APIs when you&apos;re
      ready to integrate your app with VA data. We expose VA APIs on our developer portal and
      partner with consumers to ensure VA data is reliable and secure. We never, ever, charge fees.
    </p>
    <p>
      The APIs on our portal must adhere to the highest standards for versioning, security,
      performance, and more. These APIs are created by internal VA teams and vetted through our
      publishing approval process. They follow RESTful standards to create a reliable and consistent
      API ecosystem.
    </p>
  </>
);

export default ProductionAccessOverview;
