import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import GoodToKnow from '../../../components/oauthDocs/ccg/GoodToKnow';
import { ApiAlerts, PageHeader } from '../../../components';
import { GettingStarted } from '../../../components/oauthDocs/ccg/GettingStarted';
import { AuthCodeFlowContent } from '../../../components/oauthDocs/ccg/AuthCodeFlowContent';
import { TestUsers } from '../../../components/oauthDocs/ccg/TestUsers';

import ApisLoader from '../../../components/apisLoader/ApisLoader';
import { getApi } from '../DocumentationRoot';

const EXCLUDE_ICN_FRAGMENTS = ['lgy_guaranty_remittance', 'loan-review'];

const ClientCredentialsGrantDocs = (): JSX.Element => {
  const params = useParams();
  const api = getApi(params.urlSlug);
  if (!api?.oAuthTypes?.includes('ClientCredentialsGrant')) {
    throw new Error('API does not include this auth type');
  }

  return (
    <>
      <Helmet>
        <title>{api.name} Client Credentials Grant</title>
      </Helmet>
      <ApiAlerts />
      <PageHeader header="Client Credentials Grant" subText={api.name} />
      <div className="va-api-authorization-docs vads-u-margin-top--3">
        <p>The authentication model for the {api.name} uses OAuth 2.0 with OpenID Connect.</p>
        <p>
          VA&apos;s{' '}
          <a
            href="https://datatracker.ietf.org/doc/html/rfc6749#section-4.4"
            target="_blank"
            rel="noopener noreferrer"
          >
            OAuth 2.0 Client Credentials Grant
          </a>{' '}
          (CCG) grants access by using your RSA public key in{' '}
          <a
            href="https://datatracker.ietf.org/doc/html/rfc7517"
            target="_blank"
            rel="noopener noreferrer"
          >
            JSON Web Key (JWK)
          </a>{' '}
          format, as described in the{' '}
          <a
            href="https://openid.net/specs/draft-jones-json-web-key-03.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            OpenID spec
          </a>
          .
        </p>
        <p>
          To learn how to <a href="#generating-rsa">generate an RSA key pair</a> and{' '}
          <a href="#requesting-a-token">request an access token with CCG</a>, follow the
          instructions on this page. It&apos;s important to read and understand this documentation
          before you develop your application and test it in the sandbox. If you&apos;re just
          getting started with the API, we provide a{' '}
          <a
            href="https://github.com/department-of-veterans-affairs/vets-api-clients/tree/master/samples/postman"
            target="_blank"
            rel="noopener noreferrer"
          >
            Postman collection
          </a>{' '}
          that you can use to request access tokens after generating your keys and signing up for
          sandbox access.
        </p>
        <ApisLoader hideSpinner />
        <GoodToKnow api={api} />
        <GettingStarted />
        <AuthCodeFlowContent api={api} />
        {!EXCLUDE_ICN_FRAGMENTS.includes(api.urlFragment) && <TestUsers api={api} />}
      </div>
    </>
  );
};

export { ClientCredentialsGrantDocs };
