import React, { cloneElement, useState, useRef } from 'react';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  Side,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

import './Tooltip.scss';

export interface TooltipProps {
  label: string;
  placement?: Placement;
  children: JSX.Element;
}

export const Tooltip = ({ children, label, placement = 'top' }: TooltipProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const {
    floatingStyles,
    refs,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement: finalPlacement,
  } = useFloating<HTMLElement>({
    middleware: [offset(14), flip(), shift({ padding: 8 }), arrow({ element: arrowRef })],
    onOpenChange: setOpen,
    open,
    placement,
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context, { ancestorScroll: true }),
  ]);

  const staticSide: string | undefined = {
    bottom: 'top',
    left: 'right',
    right: 'left',
    top: 'bottom',
  }[finalPlacement.split('-')[0]];

  const floatingProps = getFloatingProps({ ref: refs.setFloating, style: floatingStyles });
  const referenceProps = getReferenceProps({ ref: refs.setReference, ...children.props } as React.HTMLProps<Element>);
  const child = cloneElement(children, referenceProps);

  return (
    <>
      {child}
      <FloatingPortal>
        {open && (
          <div className="tooltip" {...floatingProps}>
            {label}
            <div
              className="arrow"
              ref={arrowRef}
              style={{
                left: arrowX ? `${arrowX}px` : '',
                top: arrowY ? `${arrowY}px` : '',
                [staticSide as Side]: '-4px',
              }}
            />
          </div>
        )}
      </FloatingPortal>
    </>
  );
};
