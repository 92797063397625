import React from 'react';
import { Link } from 'react-router-dom';

const DEFAULT_PATIENT_ICN = '1000720100V271387';
const DEFAULT_PORTAL_ID = 'TEST1234567890SERVICE';
const DEFAULT_STA3N = '993';

interface SpecificContextDetails {
  extraIntroText?: React.ReactNode;
  subjectDescription: string;
  objectDescription: string;
  objectData: Record<string, string>;
}

const apiSpecificContextDetails = (urlSlug: string): SpecificContextDetails => {
  switch (true) {
    case /(guaranty-remittance|loan-review)/.test(urlSlug):
      return {
        objectData: { portal_id: DEFAULT_PORTAL_ID },
        objectDescription: 'the lender portal ID that is associated to the specific lender ID',
        subjectDescription: 'the lender ID',
      };
    case /(clinical-health)/.test(urlSlug):
      return {
        extraIntroText: (
          <>
            This parameter must be used with the <code>launch</code> <Link to="#scopes">scope</Link> to enable the
            SMART-on-FHIR launch context.
          </>
        ),
        objectData: { patient: DEFAULT_PATIENT_ICN, sta3n: DEFAULT_STA3N },
        objectDescription: "a sta3n (a valid VistA station number) and the patient's ICN",
        subjectDescription: 'patient',
      };
    default:
      return {
        objectData: { patient: DEFAULT_PATIENT_ICN },
        objectDescription: "the patient's ICN",
        subjectDescription: 'patient or encounter',
      };
  }
};

export const createContextData = (obj: { [key: string]: string }): string[] => {
  const stringData = JSON.stringify(obj);
  const encodedData = btoa(stringData);
  return [stringData, encodedData];
};

export const getEncodedLaunchData = (urlSlug: string): string => (
  createContextData(apiSpecificContextDetails(urlSlug).objectData)[1]
);

export const getLaunchDescription = (urlSlug: string): JSX.Element => {
  const { subjectDescription, objectDescription, objectData, extraIntroText } = apiSpecificContextDetails(urlSlug);
  const [stringData, encodedData] = createContextData(objectData);

  return (
    <ul>
      <li>
        The <code>launch</code> parameter limits the scope of an access token by indicating that the token is for a
        specific {subjectDescription}. {extraIntroText}
      </li>
      <li>
        <code>launch</code> must be a Base64-encoded JSON object containing {objectDescription}, formatted as follows:
        {' '}<code>{stringData}</code>
      </li>
      <li>
        After Base64 encoding, the object will look like this:{' '}<code>{encodedData}</code>
      </li>
    </ul>
  );
};
