import * as React from 'react';
import { SectionHeaderWrapper } from '../../index';
import { PublicKeySteps } from './PublicKeySteps';

const GettingStarted = (): JSX.Element => (
  <>
    <SectionHeaderWrapper heading="Getting started" id="getting-started" />
    <h3 id="generating-rsa">Generating RSA key pair and converting public key to JWK Format</h3>
    <p>
      CCG uses{' '}
      <a
        href="https://en.wikipedia.org/wiki/Public-key_cryptography"
        target="_blank"
        rel="noopener noreferrer"
      >
        public-key cryptography
      </a>{' '}
      for authentication. You will need to generate an RSA key pair and share the public key with VA
      in JWK format when requesting sandbox access. You will later use the private key to sign token
      requests.
    </p>
    <p>
      <span className="vads-u-font-weight--bold">Note:</span> Your private key is a secret and
      should be kept secure. Anyone with access to your private key and client ID will be able to
      use your identity to make token requests to the API.
    </p>
    <PublicKeySteps />
  </>
);

GettingStarted.propTypes = {};

export { GettingStarted };
