import * as React from 'react';
import { CodeBlock } from '../../index';

export const PublicKeySteps = (): JSX.Element => (
  <>
    <ol>
      <li>
        <span className="vads-u-font-weight--bold">Generate the private key:</span> Use the
        following{' '}
        <a
          href="https://www.openssl.org/docs/man3.0/man1/openssl-rsa.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          OpenSSL
        </a>{' '}
        command to generate a private RSA key in PEM format:
        <div className="vads-u-margin-top--1">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <pre className="vads-u-background-color--gray-lightest" tabIndex={0}>
            <code>openssl genrsa -out private.pem 2048</code>
          </pre>
        </div>
      </li>
      <li>
        <span className="vads-u-font-weight--bold">Generate the public key:</span> Once you have the
        private key, you can extract the corresponding public key using the following command:
        <div className="vads-u-margin-top--1">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <pre className="vads-u-background-color--gray-lightest" tabIndex={0}>
            <code>openssl rsa -in private.pem -outform PEM -pubout -out public.pem</code>
          </pre>
        </div>
      </li>
      <li>
        <span className="vads-u-font-weight--bold">Convert the public key to JWK format:</span>{' '}
        After generating the public key in PEM format, you&apos;ll need to convert it to JWK format.{' '}
        <a href="https://www.npmjs.com/package/pem-jwk" target="_blank" rel="noopener noreferrer">
          pem-jwk
        </a>{' '}
        is a convenient Node tool for converting PEM to JWK. To convert your public key to JWK with
        pem-jwk, use the following command:
        <div className="vads-u-margin-top--1">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <pre className="vads-u-background-color--gray-lightest" tabIndex={0}>
            <code>pem-jwk public.pem &gt; public.jwk</code>
          </pre>
        </div>
      </li>
    </ol>
    <p className="vads-u-margin-top--4">Your JWK should look similar to the example shown below:</p>
    <CodeBlock
      language="json"
      code={`\
{
  "kty": "RSA",
  "n": "mYi1wUpwkJ1QB8...",
  "e": "AQAB"
}`}
    />
    <p>
      <span className="vads-u-font-weight--bold">Next, get sandbox access.</span> When you request
      access, provide your RSA public key in JWK format. We will send your client ID in an email.
    </p>
    <h3>A note on key rotation and key ID</h3>
    <p>
      If you plan to rotate keys in production, we recommend that you add the optional{' '}
      <a
        href="https://datatracker.ietf.org/doc/html/rfc7517#section-4.5"
        target="_blank"
        rel="noopener noreferrer"
      >
        &quot;kid&quot; (key ID) parameter
      </a>{' '}
      to your JWK. The key ID is a string that identifies which of multiple public keys the auth
      server will use for signature verification, should be unique for any key in a set, and must
      not exceed 255 characters. The &quot;kid&quot; parameter is{' '}
      <span className="vads-u-font-weight--bold">not</span> required for sandbox use with a single
      RSA key.
    </p>
    <p>
      If you include a key ID, your JWK should look similar to this. Replace <code>KEY_ID</code>{' '}
      with your actual key ID:
    </p>
    <CodeBlock
      language="json"
      code={`\
{
  "kty": "RSA",
  "n": "mYi1wUpwkJ1QB8...",
  "e": "AQAB",
  "kid": "KEY_ID"
}`}
    />
  </>
);
