/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Form, Formik } from 'formik';
import { VaAlert } from '@department-of-veterans-affairs/component-library/dist/react-bindings';
import { DevApplicationRequest, DevApplicationResponse } from '../../../../types/forms/apply';
import { HttpErrorResponse, ResponseType, makeRequest } from '../../../../utils/makeRequest';
import {
  TextField,
  FieldSet,
  CheckboxRadioField,
  TermsOfServiceCheckbox,
} from '../../../../components';
import { OAuthAcgAppInfo } from '../../../consumerOnboarding/components/sandbox/OAuthAcgAppInfo';
import { OAuthCcgAppInfo } from '../../../consumerOnboarding/components/sandbox/OAuthCcgAppInfo';
import { OmbInfo } from '../../../../components/ombInfo/OmbInfo';
import { lookupAttestationApi, lookupAttestationIdentifier } from '../../../../apiDefs/query';
import { APIDescription } from '../../../../apiDefs/schema';
import { hasRestrictedAccess } from '../../../../utils/restrictedAccessHelper';
import { validateForm } from './validateForm';
import { SandboxAttestation } from './SandboxAttestation';
import './SandboxAccessForm.scss';

export interface Values {
  attestationChecked?: boolean;
  description: string;
  email: string;
  firstName: string;
  lastName: string;
  oAuthApplicationType: string;
  oAuthPublicKey: string;
  oAuthRedirectURI: string;
  organization: string;
  termsOfService: boolean;
  typeAndApi: string;
}

interface SandboxAccessFormProps {
  api: APIDescription;
  authTypes: string[];
  onSuccess: (results: unknown) => void;
  urls: {
    acgPkceAuthUrl: string;
    ccgPublicKeyUrl: string;
    postUrl: string;
    termsOfServiceUrl: string;
  };
}

interface SandboxAccessFormError extends HttpErrorResponse {
  body: {
    errors?: string[];
  };
}

export const SandboxAccessForm = ({
  api,
  authTypes,
  onSuccess,
  urls,
}: SandboxAccessFormProps): JSX.Element => {
  const [submissionHasError, setSubmissionHasError] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);
  const [authType, setAuthType] = useState<string | null>();
  const setCookie = useCookies(['CSRF-TOKEN'])[1];

  const { acgPkceAuthUrl, ccgPublicKeyUrl, postUrl, termsOfServiceUrl } = urls;

  const initialValues = {
    attestationChecked: false,
    description: '',
    email: '',
    firstName: '',
    lastName: '',
    oAuthApplicationType: '',
    oAuthPublicKey: '',
    oAuthRedirectURI: '',
    organization: '',
    termsOfService: false,
    typeAndApi: authTypes.length === 1 ? `${authTypes[0]}/${api.altID}` : '',
  };

  const handleSubmit = async (values: Values): Promise<void> => {
    setSubmissionHasError(false);
    setSubmissionErrors([]);
    const applicationBody: DevApplicationRequest = {
      ...values,
      apis: values.typeAndApi,
    };
    const forgeryToken = 'CsrfBlocker';

    try {
      setCookie('CSRF-TOKEN', forgeryToken, {
        path: postUrl,
        sameSite: 'strict',
        secure: true,
      });

      const response = await makeRequest<DevApplicationResponse>(
        postUrl,
        {
          body: JSON.stringify(applicationBody),
          headers: {
            'X-Csrf-Token': forgeryToken,
            accept: 'application/json',
            'content-type': 'application/json',
          },
          method: 'POST',
        },
        { responseType: ResponseType.JSON },
      );

      const json = response.body as DevApplicationResponse;

      if (!json.token && !json.clientID && !json.email) {
        throw Error(
          'Developer Application endpoint returned successful response status with an invalid response body',
        );
      }

      onSuccess({
        ...json,
        apis: [values.typeAndApi],
        email: json.email ?? values.email,
      });
    } catch (error: unknown) {
      setSubmissionHasError(true);
      // This will only capture the errors on 4xx errors from the lighthouse-platform-backend.
      const errors = (error as SandboxAccessFormError).body.errors ?? [];
      setSubmissionErrors(errors);
    }
  };
  const authTypeChange = (event: React.FormEvent<HTMLFormElement>): void => {
    const target = event.target as HTMLInputElement;
    if (target.name === 'typeAndApi') {
      switch (target.id) {
        case `typeAndApiFormFieldacg${api.altID}`:
          setAuthType('acg');
          break;
        case `typeAndApiFormFieldccg${api.altID}`:
          setAuthType('ccg');
          break;
        case `typeAndApiFormFieldapikey${api.altID}`:
          setAuthType('apikey');
          break;
        default:
      }
    }
  };

  let attestationApi: APIDescription | undefined;
  const attestationIdentifier = lookupAttestationIdentifier([api.altID!]);
  if (attestationIdentifier) {
    attestationApi = lookupAttestationApi(attestationIdentifier);
  }

  const isRestrictedAccessApi = hasRestrictedAccess(api);

  useEffect(() => {
    if (authTypes.length === 1 && authType !== authTypes[0]) {
      setAuthType(authTypes[0]);
    }
  }, [api.altID, authType, authTypes]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {/* eslint-disable-next-line complexity */}
      {({ isSubmitting }): JSX.Element => {
        const handleSubmitButtonClick = (): void => {
          setTimeout(() => {
            const errorElements = document.querySelectorAll<HTMLElement>('[aria-invalid=true]');

            if (errorElements.length > 0) {
              errorElements[0].focus();
            }
          }, 0);
        };

        return (
          <Form noValidate onChange={authTypeChange}>
            <TextField
              label="First name"
              name="firstName"
              required
              className="vads-u-margin-top--4"
            />
            <TextField
              label="Last name"
              name="lastName"
              required
              className="vads-u-margin-top--4"
            />
            <TextField
              label="Email address"
              name="email"
              type="email"
              required
              className="vads-u-margin-top--4"
            />
            <TextField
              label="Organization"
              name="organization"
              required
              className="vads-u-margin-top--4"
            />
            <TextField
              as="textarea"
              label="Briefly describe your project and how you'll use this API."
              name="description"
              className="vads-u-margin-top--4"
            />
            {authTypes.length > 1 && (
              <FieldSet
                className="vads-u-margin-top--4"
                legend="Choose your auth type."
                name="typeAndApi"
                required
              >
                {authTypes.includes('acg') && (
                  <CheckboxRadioField
                    type="radio"
                    label={
                      <>
                        <span className="vads-u-margin-top--0 vads-u-font-weight--bold">
                          Authorization Code Grant
                        </span>
                        <p className="vads-u-margin-top--0 auth-radio-field">
                          Use Authorization Code Grant when you need to authenticate an end user and
                          gain access to data on their behalf. For more details,{' '}
                          <Link to={acgPkceAuthUrl} target="_blank">
                            read our Authorization Code Grant docs
                          </Link>
                          .
                        </p>
                      </>
                    }
                    name="typeAndApi"
                    value={`acg/${api.altID}`}
                    required
                  />
                )}
                {authTypes.includes('ccg') && (
                  <CheckboxRadioField
                    type="radio"
                    label={
                      <>
                        <span className="vads-u-margin-top--0 vads-u-font-weight--bold">
                          Client Credentials Grant
                        </span>
                        <p className="vads-u-margin-top--0 auth-radio-field">
                          Use Client Credentials Grant for server-to-server communication where no
                          user authentication is needed. For more details,{' '}
                          <Link to={ccgPublicKeyUrl} target="_blank">
                            read our Client Credentials Grant docs
                          </Link>
                          .
                        </p>
                      </>
                    }
                    name="typeAndApi"
                    value={`ccg/${api.altID}`}
                    required
                  />
                )}
                {authTypes.includes('apikey') && (
                  <CheckboxRadioField
                    type="radio"
                    label="API Key"
                    name="typeAndApi"
                    value={`apikey/${api.altID}`}
                    required
                  />
                )}
              </FieldSet>
            )}
            {authType === 'acg' && (
              <OAuthAcgAppInfo
                acgPkceAuthUrl={acgPkceAuthUrl}
                multipleTypes={authTypes.length > 1}
              />
            )}
            {authType === 'ccg' && (
              <OAuthCcgAppInfo
                ccgPublicKeyUrl={ccgPublicKeyUrl}
                multipleTypes={authTypes.length > 1}
                isRestrictedAccessApi={isRestrictedAccessApi}
              />
            )}
            {attestationApi && <SandboxAttestation api={attestationApi} />}
            <TermsOfServiceCheckbox termsOfServiceUrl={termsOfServiceUrl} />
            <button onClick={handleSubmitButtonClick} type="submit" className="vads-u-width--auto">
              {isSubmitting ? 'Sending...' : 'Submit'}
            </button>
            {submissionHasError && (
              <VaAlert status="error" visible>
                <h2>
                  We encountered a server error while saving your form. Please try again later.
                </h2>
                <p className="vads-u-margin-y--0">
                  Need assistance? Create an issue through our{' '}
                  <Link to="/support">Support page</Link>.
                </p>
                {submissionErrors.length > 0 && (
                  <ul>
                    {submissionErrors.map((item: string) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                )}
              </VaAlert>
            )}
            <hr />
            <OmbInfo expDate="11/30/2026" ombNumber="2900-0770" resBurden={9} />
          </Form>
        );
      }}
    </Formik>
  );
};
