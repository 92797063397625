import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { CodeBlock } from '../../../components';
import { getLaunchDescription, getEncodedLaunchData } from '../../../utils/launchContextHelper';

interface RetrievingTokenProps {
  apiName: string;
  scopes: string[];
  baseAuthPath: string;
}

const RetrievingTokenContent: FC<RetrievingTokenProps> = ({ apiName, scopes, baseAuthPath }) => {
  const { urlSlug } = useParams();
  const launchDescription = getLaunchDescription(urlSlug as string);
  let launch;
  if (scopes.includes('launch')) {
    launch = getEncodedLaunchData(urlSlug as string);
  }

  return (
    <>
      <h3 id="retrieving-access-token" tabIndex={-1}>
        Retrieving an access token
      </h3>
      <p>
        POST your client assertion to the <code>/token</code> service to receive an access token.
        The token endpoints for the {apiName} are shown below:
      </p>
      <ul>
        <li>
          <span className="vads-u-font-weight--bold">Sandbox:</span>{' '}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <pre tabIndex={0}>
            <code>
              https://sandbox-api.va.gov
              {baseAuthPath}/token
            </code>
          </pre>
        </li>
        <li>
          <span className="vads-u-font-weight--bold">Production:</span>{' '}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <pre tabIndex={0}>
            <code>
              https://api.va.gov
              {baseAuthPath}/token
            </code>
          </pre>
        </li>
      </ul>
      <p>The following table describes the required values you will use in your token request:</p>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Required</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>grant_type</code>
              </td>
              <td>True</td>
              <td>
                <code>client_credentials</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>client_assertion_type</code>
              </td>
              <td>True</td>
              <td>
                <code>urn:ietf:params:oauth:client-assertion-type:jwt-bearer</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>client_assertion</code>
              </td>
              <td>True</td>
              <td>
                Signed{' '}
                <a href="https://jwt.io/introduction" target="_blank" rel="noopener noreferrer">
                  JWT
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <code>scope</code>
              </td>
              <td>True</td>
              <td>
                <ul>
                  {scopes.map(scope => (
                    <li key={scope}>
                      <code>{scope}</code>
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            {launch && (
              <tr>
                <td>
                  <code>launch</code>
                </td>
                <td>True</td>
                <td>{launchDescription}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <p>
        Request a token as shown in the cURL command below. Be sure to include the scopes for the
        API. Replace <code>CLIENT_ASSERTION</code> with your own signed client assertion.
      </p>
      <CodeBlock
        withCopyButton
        language="bash"
        code={`\
curl --location --request POST 'https://sandbox-api.va.gov${baseAuthPath}/token' \\
  --header 'Accept: application/json' \\
  --header 'Content-Type: application/x-www-form-urlencoded' \\
  --data-urlencode 'grant_type=client_credentials' \\
  --data-urlencode 'client_assertion_type=urn:ietf:params:oauth:client-assertion-type:jwt-bearer' \\
  --data-urlencode 'client_assertion=CLIENT_ASSERTION' \\
  --data-urlencode 'scope=${scopes.join(' ')}' ${launch ? '\\' : ''}
  ${launch ? `--data-urlencode 'launch=${launch}'` : ''}`}
      />
      <p className="vads-u-margin-top--2">
        We will respond with a JSON object containing your access token, which will look like the
        following example:
      </p>
      <CodeBlock
        withCopyButton
        language="bash"
        code={`{
  "access_token": "eyJraWQiOi...",
  "token_type": "Bearer",
  "scope": "${scopes.join(' ')}",
  "expires_in": 300
}`}
      />
      <p>
        Use the returned <code>access_token</code> to authorize requests to our platform by
        including it in the header of HTTP requests as{' '}
        <code>Authorization: Bearer ACCESS_TOKEN</code> (replace
        <code>ACCESS_TOKEN</code> with your own access token). Your access token will remain valid
        for 5 minutes. If your access token expires, you will need to request a new one.
      </p>
    </>
  );
};

export default RetrievingTokenContent;
