import React, { useState } from 'react';
import iconHttpsSvg from '../../assets/icon-https.svg';
import iconDotGovSvg from '../../assets/icon-dot-gov.svg';
import lockIconSvg from '../../assets/lock-icon.svg';

import './GovernmentBanner.scss';

export const GovernmentBanner = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (): void => {
    setIsOpen(state => !state);
  };

  const governmentBannerHeaderClasses = isOpen
    ? 'government-banner-header expanded'
    : 'government-banner-header';

  return (
    <section className="government-banner">
      <div className="accordion">
        <header className={governmentBannerHeaderClasses}>
          <div className="inner">
            <div className="grid-col-auto">
              <img
                role="presentation"
                className="header-flag"
                src="https://s3-us-gov-west-1.amazonaws.com/content.www.va.gov/img/tiny-usa-flag.png"
                alt=""
              />
            </div>
            <div className="grid-col-fill" aria-hidden="true">
              <p className="header-text">Official website of the United States government</p>
              <p className="header-action ">Here&apos;s how you know</p>
            </div>
            <button
              className="cta-button vads-u-border--0 medium-screen:vads-u-padding-y--0p25 medium-screen:vads-u-padding-x--0 vads-u-font-weight--normal"
              onClick={handleClick}
              type="button"
              aria-expanded={isOpen}
              aria-controls="official-gov-banner"
            >
              <span className="button-text vads-u-color--link-default">
                Here&apos;s how you know
              </span>
            </button>
          </div>
        </header>

        {isOpen && (
          <div className="content" id="official-gov-banner">
            <div className="grid-row">
              <div className="col">
                <img src={iconDotGovSvg} role="presentation" alt="" />
                <div className="media-block">
                  <p>
                    <strong>Official websites use .gov</strong>
                    <br />
                    <span className="gov-site-explanation-text">
                      A <strong>.gov</strong> website belongs to an official government organization
                      in the United States.
                    </span>
                  </p>
                </div>
              </div>
              <div className="col">
                <img src={iconHttpsSvg} role="presentation" alt="" />
                <div className="media-block">
                  <p>
                    <strong>Secure .gov websites use HTTPS</strong>
                    <br />
                    <span className="gov-site-lock-text">
                      A <strong>lock</strong> ({' '}
                      <img className="lock-icon" src={lockIconSvg} alt="lock icon" /> ) or{' '}
                      <strong>https://</strong> means you&apos;ve safely connected to the .gov
                      website. Share sensitive information only on official, secure websites.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
