import * as React from 'react';
import { SectionHeaderWrapper } from '../../index';
import { ApiRequiredProps } from '../../../containers/documentation/DocumentationRoot';
import GeneratingJWTContent from './GeneratingJWTContent';
import RetrievingTokenContent from './RetrievingTokenContent';

// eslint-disable-next-line complexity
const AuthCodeFlowContent = (props: ApiRequiredProps): JSX.Element => {
  const { api } = props;
  const scopes = api.oAuthInfo?.ccgInfo?.scopes ?? [];
  const baseAuthPath = api.oAuthInfo?.ccgInfo?.baseAuthPath ?? '/oauth2/{api}/v1';
  const apiName = api.name;
  const sandboxAud = api.oAuthInfo?.ccgInfo?.sandboxAud ?? '';
  const productionAud = api.oAuthInfo?.ccgInfo?.productionAud ?? '';

  return (
    <div>
      <SectionHeaderWrapper heading="Requesting a token with CCG" id="requesting-a-token" />
      <p>
        To get authorized to make API requests, generate a{' '}
        <a href="https://jwt.io/introduction" target="_blank" rel="noopener noreferrer">
          JSON web token (JWT)
        </a>{' '}
        and sign it using your private key. You&apos;ll then use the signed JWT as a client
        assertion (CCG authorization credential) when requesting an access token.
      </p>
      <GeneratingJWTContent
        apiName={apiName}
        productionAud={productionAud}
        sandboxAud={sandboxAud}
      />
      <RetrievingTokenContent apiName={apiName} baseAuthPath={baseAuthPath} scopes={scopes} />
    </div>
  );
};

export { AuthCodeFlowContent };
