import React from 'react';
import classNames from 'classnames';
import { VaIcon } from '@department-of-veterans-affairs/component-library/dist/react-bindings';
import './ApiTag.scss';

export interface ApiTagProps {
  showLock?: boolean;
  tagName: string;
}

export const ApiTag = ({ showLock, tagName }: ApiTagProps): JSX.Element => (
  <span
    className={classNames(
      'api-tag',
      'vads-u-background-color--gray-lightest',
      'vads-u-color--base',
    )}
  >
    {showLock && (
      <VaIcon
        className={classNames('api-tag-fa-lock', 'vads-u-color--gray-medium')}
        icon="lock"
        size={2}
      />
    )}
    <span>{tagName.replace(/-/g, ' ')}</span>
  </span>
);
