import React from 'react';
import { createFlags, StaticBackend } from 'flag';
import { AppFlags, getFlags } from './flags';

const { Flag, FlagBackendProvider, useFlag } = createFlags<AppFlags>();

export { Flag, useFlag };

interface FlagsProviderProps extends React.PropsWithChildren {
  flags?: AppFlags; // If provided, these flags will override the defaults from getFlags()
}

const FlagsProvider: React.FC<FlagsProviderProps> = ({ children, flags }) => {
  const backend = new StaticBackend<AppFlags>(flags ?? getFlags());

  return <FlagBackendProvider backend={backend}>{children}</FlagBackendProvider>;
};

export default FlagsProvider;
