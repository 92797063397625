import { VaAlert } from '@department-of-veterans-affairs/component-library/dist/react-bindings';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { isApiDeactivated } from '../../apiDefs/deprecated';
import { lookupApiCategory } from '../../apiDefs/query';
import { APIDescription } from '../../apiDefs/schema';
import { PageHeader } from '../../components';
import { FLAG_API_ENABLED_PROPERTY, PAGE_HEADER_ID } from '../../types/constants';
import { Flag } from '../../FlagsProvider';

interface ApiListItemProps {
  urlSlug: string;
  name: string;
}

const ApiListItem: React.FC<ApiListItemProps> = ({ urlSlug, name }) => (
  <Flag
    keyPath={[FLAG_API_ENABLED_PROPERTY, urlSlug]}
    defaultValue={false}
    render={(): React.ReactNode => (
      <li>
        <Link to={`/explore/api/${urlSlug}`}>
          {name}
        </Link>
      </li>
    )}
  />
);

const ApiNotFoundPage = (): React.ReactElement => {
  const { apiCategoryKey } = useParams();
  const category = lookupApiCategory(apiCategoryKey as string);

  return (
    <div role="region" aria-labelledby={PAGE_HEADER_ID}>
      <VaAlert status="warning" visible uswds>
        <p className="vads-u-margin-y--0">Page not found.</p>
        <p className="vads-u-margin-top--1">
          Try using the links below or the search bar to find your way forward.
        </p>
      </VaAlert>
      {category.name && <PageHeader header={category.name} />}
      <ul>
        {category.apis
          .filter((item: APIDescription) => !isApiDeactivated(item))
          .map((item: APIDescription) => (
            <ApiListItem
              key={item.urlSlug}
              name={item.name}
              urlSlug={item.urlSlug}
            />
          ))}
      </ul>
    </div>
  );
};

export default ApiNotFoundPage;
