import React from 'react';
import { VaBreadcrumbs } from '@department-of-veterans-affairs/component-library/dist/react-bindings';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { RootState, UserStore } from '../../types';
import { APIDescription } from '../../apiDefs/schema';

const apiRoutes = [
  { href: '/docs', isRouterLink: true, label: 'Docs' },
  { href: '/authorization-code', isRouterLink: true, label: 'Authorization Code Grant' },
  { href: '/client-credentials', isRouterLink: true, label: 'Client Credentials Grant' },
  { href: '/release-notes', isRouterLink: true, label: 'Release notes' },
  { href: '/sandbox-access', isRouterLink: true, label: 'Sandbox access' },
];
interface ApiBreadcrumbsProps {
  api: APIDescription;
}

export const ApiBreadcrumbs = ({ api }: ApiBreadcrumbsProps): JSX.Element | null => {
  const location = useLocation();
  const navigate = useNavigate();
  // Used to maintain filters when navigating back to the explore APIs page
  const exploreApisPathSessionStorage = sessionStorage.getItem('exploreApisPath');
  const exploreApisPath = exploreApisPathSessionStorage
    ? DOMPurify.sanitize(exploreApisPathSessionStorage)
    : '/explore';
  const selector = (state: RootState): UserStore => state.userStore;
  const { id: userId, testUserHash } = useSelector(selector);

  if (!location.pathname.includes(`/explore/api/${api.urlSlug}`)) {
    return null;
  }

  const handleRouteChange = (e: CustomEvent): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    navigate(e.detail.href as string);
  };

  const testUsersRoute = [
    {
      href: `/test-users/${userId}/${testUserHash}`,
      isRouterLink: true,
      label: 'Test users',
    },
  ];

  const breadcrumbs = [
    { href: '/', isRouterLink: true, label: 'Home' },
    { href: exploreApisPath, isRouterLink: true, label: 'Explore APIs' },
    { href: `/explore/api/${api.urlSlug}`, isRouterLink: true, label: api.name },
    ...apiRoutes
      .filter(({ href }) => location.pathname === `/explore/api/${api.urlSlug}${href}`)
      .map(({ href, isRouterLink, label }) => ({ href, isRouterLink, label })),
    ...testUsersRoute
      .filter(
        ({ href }) =>
          location.pathname === `/explore/api/${api.urlSlug}${href}` &&
          !api.oAuthInfo?.acgInfo?.disableTestUsersPage &&
          !!userId &&
          testUserHash,
      )
      .map(({ href, isRouterLink, label }) => ({ href, isRouterLink, label })),
  ];

  return (
    <VaBreadcrumbs
      className="vads-u-margin-top--2 vads-u-margin-x--4 vads-u-border-bottom--1px vads-u-border-color--gray-lighter"
      breadcrumbList={breadcrumbs}
      label="Breadcrumb"
      uswds
      onRouteChange={handleRouteChange}
      homeVeteransAffairs={false}
    />
  );
};
